<template>
    <el-card class="box-card">
        <el-button type="primary" class="d-block mr-0 ml-auto" icon="el-icon-plus" @click="handleAddClick">
            {{ $t('account.profile.contact-add') }}
        </el-button>
        <div class="separator"></div>
        <template v-if="total > limit">
            <el-pagination
                layout="prev, pager, next"
                :total="total"
                :page-size.sync="limit"
                :current-page.sync="page"
                @current-change="changePage"
                class="center"
            >
            </el-pagination>
            <div class="mini-separator"></div>
        </template>
        <el-table :data="contactsList" style="width: 100%">
            <el-table-column prop="id" label="ID" width="90px"> </el-table-column>
            <el-table-column prop="name" :label="$t('general.name')"> </el-table-column>
            <el-table-column :label="$t('account.profile.contact-type')" prop="type">
                <template slot-scope="scope">
                    <span
                        :class="{ 'bold-text': scope.row.type === 'admin', 'cel-color': scope.row.type === 'admin' }"
                        >{{ getContactType(scope.row.type) }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column prop="email" label="Email"> </el-table-column>
            <el-table-column :label="$t('account.profile.phone')" prop="phone"> </el-table-column>
            <el-table-column label="Status" prop="status" width="90px">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">
                        {{ scope.row.status == 1 ? $t('general.active') : $t('general.inactive') }}</el-tag
                    >
                </template>
            </el-table-column>
            <el-table-column prop="type" :label="$t('general.action')" width="90px">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="handleEditClick(scope.row)" plain>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <template v-if="total > limit">
            <div class="mini-separator"></div>
            <el-pagination
                layout="prev, pager, next"
                :total="total"
                :page-size.sync="limit"
                :current-page.sync="page"
                @current-change="changePage"
                class="center"
            >
            </el-pagination>
        </template>
        <el-dialog
            :title="
                actionType === 'insert'
                    ? $t('account.profile.contact-action-type-insert')
                    : $t('account.profile.contact-action-type-edit')
            "
            :visible.sync="dialogVisible"
            width="50%"
            :before-close="clearDialogData"
        >
            <el-form
                :model.sync="contactForm"
                :rules="contactRules"
                ref="contactForm"
                label-position="top"
                label-width="100px"
            >
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="10">
                        <el-form-item :label="$t('account.profile.contact-name')" prop="name" required>
                            <el-input v-model="contactName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="10">
                        <el-form-item :label="$t('account.profile.contact-type')" prop="type">
                            <el-select
                                class="fullWidth"
                                v-model="contactType"
                                placeholder="Select"
                                :disabled="contactType === 'admin' && actionType === 'edit'"
                                @change="checkTypeAndSetStatus"
                            >
                                <el-option
                                    v-for="type in contactTypes"
                                    v-if="
                                        !hasAdminContact ||
                                            type.value !== 'admin' ||
                                            (type.value === 'admin' && contactType === 'admin')
                                    "
                                    :key="type.value"
                                    :label="type.label"
                                    :value="type.value"
                                >
                                    <span>{{ type.label }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="10">
                        <el-form-item label="Email" prop="email" required>
                            <el-input v-model="contactEmail" type="email"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="10">
                        <el-form-item :label="$t('account.profile.phone')" prop="phone" required>
                            <vue-tel-input
                                v-model="contactPhone"
                                :inputOptions="{ showDialCode: true, tabindex: 0 }"
                                defaultCountry="ro"
                                mode="international"
                                required
                                @validate="onPhoneValidate"
                            >
                            </vue-tel-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="10">
                        <el-form-item label="Status">
                            <el-switch v-model="contactStatus" :disabled="contactType === 'admin' && contactStatus">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clearDialogData">Cancel</el-button>
                <el-button type="primary" @click="saveContact">{{ $t('general.save') }}</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import i18n from '@/lang';
import { call, sync, get } from 'vuex-pathify';
import { VueTelInput } from 'vue-tel-input';
import cloneDeep from 'lodash.clonedeep';

const phoneStatus = {
    number: '',
    status: false
};

const validatePhone = (rule, value, callback) => {
    if (!phoneStatus.status) return callback(Error(i18n.t('account.profile.invalid-contact-phone')));
    return callback();
};

export default {
    components: {
        VueTelInput
    },
    data: () => ({
        contactTypes: [
            {
                value: 'admin',
                label: i18n.t('account.profile.contact-type-admin')
            },
            {
                value: 'tehnic',
                label: i18n.t('account.profile.contact-type-tehnic')
            },
            {
                value: 'sales',
                label: i18n.t('account.profile.contact-type-sales')
            },
            {
                value: 'financial',
                label: i18n.t('account.profile.contact-type-financial')
            },
            {
                value: 'op',
                label: i18n.t('account.profile.contact-type-op')
            },
            {
                value: 'shipping',
                label: i18n.t('account.profile.contact-type-shipping')
            }
        ],
        actionType: 'insert',
        dialogVisible: false,
        contactRules: {
            name: [
                {
                    required: true,
                    message: i18n.t('account.profile.invalid-contact-name'),
                    trigger: 'blur'
                }
            ],
            email: [
                {
                    type: 'email',
                    required: true,
                    message: i18n.t('account.profile.invalid-contact-email'),
                    trigger: 'blur'
                }
            ],
            phone: [
                {
                    validator: validatePhone,
                    trigger: 'blur'
                }
            ],
            type: [
                {
                    required: true,
                    message: i18n.t('account.profile.invalid-contact-type'),
                    trigger: 'change'
                }
            ]
        }
    }),
    methods: {
        getAffiliateContacts: call('account/GetAffiliateContacts'),
        saveAffiliateContact: call('account/SaveAffiliateContact'),
        checkTypeAndSetStatus(type) {
            if (type == 'admin') this.contactStatus = true;
        },
        getContactType(type) {
            return this.contactTypes.find((t) => t.value === type).label;
        },
        async handleAddClick() {
            this.dialogVisible = true;
        },
        handleEditClick(contact) {
            this.dialogVisible = true;
            setTimeout(() => {
                this.$store.set('account/informations@contactForm', cloneDeep(contact));
                this.$store.set('account/informations@contactForm.status', !!contact.status);
                this.actionType = 'edit';
            }, 500);
        },
        clearDialogData() {
            this.$refs.contactForm.resetFields();
            this.dialogVisible = false;
            this.actionType = 'insert';
            // return this.$store.set('account/informations@contactForm', {
            // 	name: '',
            // 	phone: '',
            // 	email: '',
            // 	status: 1,
            // 	type: ''
            // });
        },
        onPhoneValidate(data) {
            this.contactPhone = data.number;
            phoneStatus.number = data.number;
            phoneStatus.status = data.valid;
        },
        saveContact() {
            this.$refs.contactForm.validate(async (valid) => {
                if (!valid) return false;
                try {
                    await this.saveAffiliateContact();
                    await this.clearDialogData();
                    this.sbMsg({
                        type: 'success',
                        message: this.$t('general.changes-saved')
                    });
                } catch (err) {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'saveContact',
                            params: this.contactForm
                        }
                    });
                }
            });
        },
        changePage(page) {
            this.$store.set('account/informations@contacts.page', page);
            this.getAffiliateContacts();
        }
    },
    computed: {
        ...sync('account/informations@contacts', {
            contactsList: 'list',
            page: 'page',
            total: 'total',
            limit: 'limit'
        }),
        ...sync('account/informations@contactForm', {
            contactName: 'name',
            contactEmail: 'email',
            contactPhone: 'phone',
            contactStatus: 'status',
            contactType: 'type'
        }),
        contactForm: get('account/informations@contactForm'),
        hasAdminContact() {
            return !!this.contactsList.find((c) => c.type === 'admin');
        }
    },
    async mounted() {
        try {
            await this.getAffiliateContacts();
        } catch (err) {
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getAffiliateContacts'
                }
            });
        }
    }
};
</script>

<style></style>
